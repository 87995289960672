import { trackEvent } from './src/utils/eventTrack'
import highlander from './src/utils/highlander'
import alternator from './src/utils/alternator'

export { wrapRootElement } from './gatsby-shared'

export const onRouteUpdate = async ({ prevLocation, location }, pluginOptions = {}) => {
  // eslint-disable-next-line
  const _hsq = (window._hsq = window._hsq || [])
  _hsq.push(['setPath', `${location.pathname}${location.search}`])

  if (prevLocation) {
    // only tracking page view when prev location. original page view on page load should be done by hubspot (we just have the page path set for them)\
    // Minimum delay for reactHelmet's requestAnimationFrame (see code for gatsby's google analytics: https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-analytics/src/gatsby-browser.js)
    const delay = Math.max(32, pluginOptions.pageTransitionDelay || 0)
    setTimeout(() => {
      _hsq.push(['trackPageView'])
    }, delay)

    const utmParams = JSON.parse(sessionStorage.getItem('utm_params'))
    const utmParamsInitial = JSON.parse(sessionStorage.getItem('utm_params_initial'))
    trackEvent(`Page Viewed`, {
      'Initial UTM Campaign': utmParamsInitial ? utmParamsInitial.utm_campaign : '',
      'Initial UTM Content': utmParamsInitial ? utmParamsInitial.utm_content : '',
      'Initial UTM Medium': utmParamsInitial ? utmParamsInitial.utm_medium : '',
      'Initial UTM Source': utmParamsInitial ? utmParamsInitial.utm_source : '',
      'Initial UTM Term': utmParamsInitial ? utmParamsInitial.utm_term : '',
      'Latest UTM Campaign': utmParams ? utmParams.utm_campaign : '',
      'Latest UTM Content': utmParams ? utmParams.utm_content : '',
      'Latest UTM Medium': utmParams ? utmParams.utm_medium : '',
      'Latest UTM Source': utmParams ? utmParams.utm_source : '',
      'Latest UTM Term': utmParams ? utmParams.utm_term : '',
    })
  }
}

export const onClientEntry = () => {
  localStorage.setItem('ae_analytics_disabled', false)

  // Ensure analytics don't fire if bot query params are present
  if (
    window.location.search.includes('ae_analytics_enabled=0') ||
    window.location.search.includes('ae_airbrake_override') ||
    window.location.search.includes('twclid')
  ) {
    localStorage.setItem('ae_analytics_disabled', true)
  }
  if (window.location.search.includes('ae_analytics_enabled=1')) {
    localStorage.setItem('ae_analytics_disabled', false)
  }

  const params = new URLSearchParams(typeof window !== 'undefined' && window.location.search)

  const redirected = window.sessionStorage.getItem('redirected')
  const utmSourceParam = params.get('utm_source')
  if (utmSourceParam?.includes('intent') && !redirected) {
    window.location.pathname = '/get-started'
    window.sessionStorage.setItem('redirected', true)
  }

  const utmParams = {
    utm_campaign: params.get('utm_campaign') || '',
    utm_content: params.get('utm_content') || '',
    utm_medium: params.get('utm_medium') || '',
    utm_source: params.get('utm_source') || '',
    utm_term: params.get('utm_term') || '',
  }

  if (!sessionStorage.getItem('utm_params')) {
    sessionStorage.setItem('utm_params', JSON.stringify(utmParams))
  }

  if (
    params.get('utm_campaign') ||
    params.get('utm_content') ||
    params.get('utm_medium') ||
    params.get('utm_source') ||
    params.get('utm_term')
  ) {
    if (!sessionStorage.getItem('utm_params_initial')) {
      sessionStorage.setItem('utm_params_initial', JSON.stringify(utmParams))
    }

    sessionStorage.setItem('utm_params', JSON.stringify(utmParams))
  }

  const gclidParam = params.get('gclid') || ''
  sessionStorage.setItem('gclid_param', JSON.stringify(gclidParam))
}

export const onInitialClientRender = async () => {
  const utmParams = JSON.parse(sessionStorage.getItem('utm_params'))
  const utmParamsInitial = JSON.parse(sessionStorage.getItem('utm_params_initial'))
  trackEvent(`Page Viewed`, {
    'Initial UTM Campaign': utmParamsInitial ? utmParamsInitial.utm_campaign : '',
    'Initial UTM Content': utmParamsInitial ? utmParamsInitial.utm_content : '',
    'Initial UTM Medium': utmParamsInitial ? utmParamsInitial.utm_medium : '',
    'Initial UTM Source': utmParamsInitial ? utmParamsInitial.utm_source : '',
    'Initial UTM Term': utmParamsInitial ? utmParamsInitial.utm_term : '',
    'Latest UTM Campaign': utmParams ? utmParams.utm_campaign : '',
    'Latest UTM Content': utmParams ? utmParams.utm_content : '',
    'Latest UTM Medium': utmParams ? utmParams.utm_medium : '',
    'Latest UTM Source': utmParams ? utmParams.utm_source : '',
    'Latest UTM Term': utmParams ? utmParams.utm_term : '',
  })

  // fix h1 issues
  highlander()
  alternator()
}

/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import FocusLock from 'react-focus-lock'
import { theme } from '../../styles'
import companyLogo from '../../../static/logos/company-logo-violet.svg'
import companyGraphicLogo from '../../../static/logos/company-logo-graphic-only.svg'
import CrossIcon from '../../../static/icons/misc-icons/close-icon.svg'
import HamburgerIcon from '../../../static/icons/misc-icons/hamburger-menu-icon.svg'
import Button from '../common/Button'

import { HeaderNav } from './HeaderNav'
import useEscape from '../common/modal/useEchap'
import { useUserLang } from '../../context/UserLangContext'
import generateHeaderTranslations from '../../data/generateHeaderTranslations'
import generateAriaLabelTranslations from '../../data/generateAriaLabelTranslations'

const AudioEyeLogo = styled.img`
  width: 124px;
`

const AudioEyeGraphicLogo = styled.img`
  width: 24px;
`

const Responsive = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;

  .button {
    margin: unset;
  }

  @media (min-width: 1000px) {
    display: none;
  }
`

const MobMenuButton = styled.button`
  background: transparent;
  cursor: pointer;
`

const MobContentWrapper = styled.div`
  position: absolute;
  transition-property: transform, opacity;
  transition: 200ms;
  transform-origin: 100% 0;
  width: calc(100vw - var(--scrollbar-width));
  top: 0;
  left: 0;
  background-color: transparent;

  ${p =>
    p.isOpen
      ? `
      z-index: 9999;
      opacity: 1;
      pointer-events: auto;
      transform: scale(1);
  `
      : `
      z-index: -9999;
      pointer-events: none;
      opacity: 0;
      transform: scale(0.90);
  `};
`

const MobContent = styled.div`
  margin: 16px;
  border-radius: 8px;
  background-color: ${theme.color.white};
  display: grid;
  box-shadow: 0 50px 100px 5px rgba(50, 50, 93, 0.25), 0 30px 60px -10px rgba(0, 0, 0, 0.3);
`

const MobHeader = styled.section`
  border-radius: 8px 8px 0px 0px;
  padding-top: 16px;
  padding: 24px;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
`

const MobFooterWrapper = styled.section`
  border-radius: 0px 0px 8px 8px;
  padding: 24px;
  padding-top: 16px;
  display: grid;
  gap: 16px;
  background-color: ${theme.color.offWhite};
`

const MobileNav = styled.nav`
  display: grid;

  @media (min-width: 1000px) {
    display: none;
  }
`

const CloseButton = styled.button`
  padding: 4px;
  background: none;
  align-self: flex-end;
`

const CloseIcon = styled.img`
  width: 14px;
`

const HamburgerButton = styled.img`
  width: 100%;
`

const LinkCta = styled(Button)`
  @media (max-width: 1050px) {
    width: fit-content !important;
    margin: 8px 0 !important;
  }
`

const MobileMenu = ({
  hideHeaderFooterNav,
  hideHeaderNav,
  isOpen,
  setIsOpen,
  showCTA,
  showScanSiteCTA,
  showSecondaryCTA,
  headerLinks,
  setIsAnyMenuOpen,
  removeLogoLink,
  isDai,
}) => {
  useEscape(isOpen, () => setIsOpen(false))

  const [scanYourSiteUrl, setSiteScanUrl] = useState(false)
  const [utmParams, setUtmParams] = useState(null)

  const { userLang } = useUserLang()
  const languageData = generateHeaderTranslations({ userLang })
  const ariaLabels = generateAriaLabelTranslations({ userLang })

  useEffect(() => setUtmParams(JSON.parse(sessionStorage.getItem('utm_params'))), [])

  useEffect(() => {
    if (isDai) {
      setSiteScanUrl(`${window.location.pathname}#site-scan`)
    }
  }, [])

  const Logo = removeLogoLink ? (
    <AudioEyeLogo alt="AudioEye logo homepage" src={companyLogo} />
  ) : (
    <Link to="/">
      <AudioEyeLogo alt="AudioEye logo homepage" src={companyLogo} />
    </Link>
  )

  const SecondaryLogo = removeLogoLink ? (
    <AudioEyeGraphicLogo alt="AudioEye logo homepage" src={companyGraphicLogo} tabIndex={isOpen ? 0 : -1} />
  ) : (
    <Link to="/" tabIndex={isOpen ? 0 : -1}>
      <AudioEyeGraphicLogo alt="AudioEye logo homepage" src={companyGraphicLogo} />
    </Link>
  )

  const TertiaryLogo = removeLogoLink ? (
    <AudioEyeLogo alt="AudioEye logo homepage" src={companyLogo} tabIndex={isOpen ? 0 : -1} />
  ) : (
    <Link to="/" tabIndex={isOpen ? 0 : -1}>
      <AudioEyeLogo alt="AudioEye logo homepage" src={companyLogo} />
    </Link>
  )

  return (
    <>
      {!hideHeaderFooterNav && !hideHeaderNav && (
        <Responsive>
          {Logo}
          <MobMenuButton onClick={() => setIsOpen(true)} aria-label={ariaLabels?.openTheMenu}>
            <HamburgerButton src={HamburgerIcon} alt="" />
          </MobMenuButton>
          <MobContentWrapper aria-hidden={!isOpen} isOpen={isOpen}>
            <MobContent>
              <FocusLock disabled={!isOpen} returnFocus>
                <MobHeader>
                  {SecondaryLogo}
                  <CloseButton
                    role="button"
                    aria-label={ariaLabels?.closeMenu}
                    className="close-menu-button"
                    onClick={() => setIsOpen(false)}
                    tabIndex={isOpen ? 0 : -1}
                  >
                    <CloseIcon alt="" src={CrossIcon} />
                  </CloseButton>
                </MobHeader>
                <MobileNav role="navigation">
                  <HeaderNav
                    headerLinks={headerLinks}
                    mobile
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    showCTA={showCTA}
                    showScanSiteCTA={showScanSiteCTA}
                    showSecondaryCTA={showSecondaryCTA}
                    setIsAnyMenuOpen={setIsAnyMenuOpen}
                  />
                </MobileNav>
                <MobFooterWrapper>
                  <LinkCta
                    text={languageData?.loginLinkText}
                    to={process.env.GATSBY_AEYE_PORTAL_LOGIN_URL}
                    tabIndex={isOpen ? 0 : -1}
                    buttonType="link CTA"
                    loginLink
                  />
                  {showCTA && utmParams?.utm_campaign !== 'toolbar_referral' && (
                    <Button
                      text={languageData?.scheduleDemoButtonText}
                      to="#schedule-demo"
                      aria-label={languageData?.scheduleDemoButtonText}
                      size="small"
                      onClick={() => setIsOpen(false)}
                      tabIndex={isOpen ? 0 : -1}
                    />
                  )}
                  {showSecondaryCTA && (
                    <Button
                      text={languageData?.startFreeTrialButtonText}
                      to="#get-started-free"
                      size="small"
                      buttonType="secondary"
                      onClick={() => setIsOpen(false)}
                      tabIndex={isOpen ? 0 : -1}
                    />
                  )}
                </MobFooterWrapper>
              </FocusLock>
            </MobContent>
          </MobContentWrapper>
        </Responsive>
      )}

      {(hideHeaderFooterNav || hideHeaderNav) && (
        <Responsive style={{ padding: '14px 0' }}>
          {TertiaryLogo}
          {showCTA && utmParams?.utm_campaign !== 'toolbar_referral' && (
            <Button
              text={languageData?.scheduleDemoButtonText}
              to="#schedule-demo"
              aria-label={languageData?.scheduleDemoButtonText}
              size="small"
              onClick={e => {
                e.preventDefault()
                setIsOpen(false)
              }}
              tabIndex={isOpen ? 0 : -1}
            />
          )}
          {showScanSiteCTA && (
            <Button text="Scan Your Site" to={scanYourSiteUrl} aria-label="Scan Your Site" size="xx-small" />
          )}
        </Responsive>
      )}
    </>
  )
}

export default MobileMenu

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.audioeye.com","noHash":true,"noQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"audioeye-web","accessToken":"MC5YMkQtSHhNQUFDRUF6a1Ja.LG5o77-9De-_vUwR77-977-977-9V--_vQrvv73vv70e77-9Xu-_vT_vv70l77-977-9Tu-_vSPvv71FYCE","routes":[{"type":"page","path":"/:folder?/:uid","resolvers":{"folder":"folder"}},{"type":"post","path":"/post/:uid"},{"type":"author","path":"/author/:uid"}],"promptForAccessToken":true,"apiEndpoint":"https://audioeye-web.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Digital & Website Accessibility Solutions | AudioEye","short_name":"Digital & Website Accessibility Solutions | AudioEye","description":"AudioEye's patented industry-leading web accessibility solution and team of experts provide full support to help your website reach ADA & WCAG compliance.","start_url":"/","background_color":"#EBEDF2","theme_color":"#3D63AE","display":"standalone","icon":"src/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bf66394838cb1f4b8fef2a04862602e3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-datadog-rum/gatsby-browser.js'),
      options: {"plugins":[],"site":"datadoghq.com","sampleRate":100,"enabled":true,"service":"audioeye_web","env":"prod","proxyUrl":"https://dd.audioeye.com","applicationId":"8e66bd13-88cf-419e-b78e-a1bf33d16f32","clientToken":"pub5d27e37f465b7c0ffdd7fcd69654da10","premiumSampleRate":100,"trackInteractions":true,"defaultPrivacyLevel":"mask-user-input","trackSessionAcrossSubdomains":true,"useCrossSiteSessionCookie":true,"version":"production"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NP3NVLJ","includeInDevelopment":true,"routeChangeEventName":"Loaded a Page","defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie-player/dist/LottiePlayerLight'

export default function LottieLogo() {
  const [animationData, setAnimationData] = useState()
  const [play, setPlay] = useState(false)

  useEffect(() => {
    setAnimationData({
      v: '4.8.0',
      meta: { g: 'LottieFiles AE 1.0.0', a: '', k: '', d: '', tc: '' },
      fr: 60,
      ip: 0,
      op: 300,
      w: 921,
      h: 348,
      nm: 'audioeye_logo',
      ddd: 0,
      assets: [],
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Logo',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.057], y: [1] }, o: { x: [0.333], y: [0] }, t: 1, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 26, s: [90] },
                { i: { x: [0.573], y: [1] }, o: { x: [1], y: [0] }, t: 37, s: [90] },
                { t: 60, s: [0] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [135, 174, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-23.521, 0],
                        [-16.905, 13.861],
                        [23.521, 0],
                        [16.905, -13.861],
                      ],
                      o: [
                        [23.567, 0],
                        [-16.89, -13.813],
                        [-23.567, 0],
                        [16.89, 13.813],
                      ],
                      v: [
                        [-0.099, -53.72],
                        [61.997, -75.9],
                        [0, -98],
                        [-62.097, -75.82],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-24.233, 0],
                        [-17.014, 14.237],
                        [24.233, 0],
                        [17.014, -14.237],
                      ],
                      o: [
                        [23.92, 0],
                        [-17.106, -14.572],
                        [-23.92, 0],
                        [17.106, 14.572],
                      ],
                      v: [
                        [-0.001, 98],
                        [62.852, 75.193],
                        [-0.665, 51.825],
                        [-63.518, 74.632],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 24.153],
                        [14.181, 16.998],
                        [0, -24.153],
                        [-14.181, -16.998],
                      ],
                      o: [
                        [0, -23.868],
                        [-14.486, 17.083],
                        [0, 23.868],
                        [14.486, -17.083],
                      ],
                      v: [
                        [98, 0],
                        [75.286, -62.741],
                        [52.062, 0.607],
                        [74.776, 63.348],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 23.689],
                        [13.577, 16.818],
                        [0, -23.689],
                        [-13.577, -16.818],
                      ],
                      o: [
                        [0, -23.297],
                        [-13.99, 16.943],
                        [0, 23.297],
                        [13.99, -16.943],
                      ],
                      v: [
                        [-53.899, -0.846],
                        [-75.605, -62.359],
                        [-98, -0.002],
                        [-76.294, 61.512],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.167], y: [0] },
                        t: 0,
                        s: [0.441672176123, 0.098000049591, 0.881999969482, 1],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 1,
                        s: [0.441672176123, 0.098000049591, 0.881999969482, 1],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 21,
                        s: [0.078431375325, 0.074509806931, 0.086274512112, 1],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 42,
                        s: [0.078431375325, 0.074509806931, 0.086274512112, 1],
                      },
                      { t: 60, s: [0.441672176123, 0.098000049591, 0.881999969482, 1] },
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Logo',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 300,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'audioeye',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [594.378, 174.147, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.153, 5.657],
                        [13.148, 0],
                        [0, 13.148],
                        [-6.115, 3.822],
                        [-12.078, 1.529],
                        [0, 0],
                        [0, 0],
                        [12.995, 0],
                        [0.612, -10.854],
                        [0, 0],
                        [-17.581, 0],
                        [0, -22.168],
                        [0, 0],
                        [-1.682, -6.268],
                        [0, 0],
                      ],
                      o: [
                        [-3.669, 10.702],
                        [-15.288, 0],
                        [0, -7.338],
                        [5.504, -3.21],
                        [0, 0],
                        [0, 0],
                        [0, -15.747],
                        [-11.466, 0],
                        [0, 0],
                        [0.917, -15.747],
                        [20.486, 0],
                        [0, 0],
                        [0, 11.925],
                        [0, 0],
                        [-0.764, -4.128],
                      ],
                      v: [
                        [-238.85, 24.843],
                        [-265.451, 41.966],
                        [-290.829, 19.951],
                        [-281.503, 2.828],
                        [-256.431, -4.051],
                        [-239.461, -6.039],
                        [-239.461, -7.262],
                        [-257.96, -29.277],
                        [-277.07, -12.46],
                        [-288.536, -12.46],
                        [-258.113, -38.144],
                        [-228.76, -6.497],
                        [-228.76, 13.377],
                        [-226.314, 40.131],
                        [-237.474, 40.131],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -8.408],
                        [-10.243, 0],
                        [0, 15.594],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 8.256],
                        [14.218, 0],
                        [0, 0],
                        [0, 0],
                        [-12.995, 1.835],
                      ],
                      v: [
                        [-279.669, 19.645],
                        [-263.005, 33.099],
                        [-239.308, 6.803],
                        [-239.308, 1.911],
                        [-261.017, 4.969],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [11.619, 0],
                        [0, 18.193],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-10.396, 0],
                        [0, 15.441],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [-3.516, 10.09],
                        [-19.11, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 14.982],
                        [13.912, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-156.129, 24.384],
                        [-180.895, 41.966],
                        [-207.038, 12.154],
                        [-207.038, -36.309],
                        [-196.489, -36.309],
                        [-196.489, 11.084],
                        [-179.061, 32.793],
                        [-156.74, 5.427],
                        [-156.74, -36.309],
                        [-146.039, -36.309],
                        [-146.039, 40.131],
                        [-156.129, 40.131],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [12.23, 0],
                        [0, 23.849],
                        [-19.416, 0],
                        [-4.586, -10.702],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [-4.434, 11.313],
                        [-18.957, 0],
                        [0, -23.544],
                        [11.007, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-69.871, 23.926],
                        [-95.708, 41.966],
                        [-127.201, 1.911],
                        [-94.485, -38.144],
                        [-70.482, -22.397],
                        [-70.482, -70.707],
                        [-59.781, -70.707],
                        [-59.781, 40.131],
                        [-69.871, 40.131],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 4,
                  ty: 'sh',
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -19.11],
                        [-14.218, 0],
                        [0, 16.97],
                        [0, 0],
                        [14.218, 0],
                      ],
                      o: [
                        [0, 18.957],
                        [14.065, 0],
                        [0, 0],
                        [0, -17.428],
                        [-14.371, 0],
                      ],
                      v: [
                        [-115.888, 1.911],
                        [-93.109, 32.487],
                        [-70.33, 3.593],
                        [-70.33, 0.382],
                        [-92.956, -28.665],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 5,
                  ty: 'sh',
                  ix: 6,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-21.883, -50.068],
                        [-33.961, -50.068],
                        [-33.961, -66.885],
                        [-21.883, -66.885],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 6',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 6,
                  ty: 'sh',
                  ix: 7,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-22.648, 40.131],
                        [-33.196, 40.131],
                        [-33.196, -36.309],
                        [-22.648, -36.309],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 7',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 7,
                  ty: 'sh',
                  ix: 8,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -24.919],
                        [21.097, 0],
                        [0, 24.919],
                        [-21.25, 0],
                      ],
                      o: [
                        [0, 24.919],
                        [-21.25, 0],
                        [0, -24.919],
                        [21.097, 0],
                      ],
                      v: [
                        [66.296, 1.911],
                        [31.287, 41.966],
                        [-3.876, 1.911],
                        [31.287, -38.144],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 8',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 8,
                  ty: 'sh',
                  ix: 9,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -19.874],
                        [-14.829, 0],
                        [0, 19.874],
                        [14.676, 0],
                      ],
                      o: [
                        [0, 19.874],
                        [14.676, 0],
                        [0, -19.874],
                        [-14.829, 0],
                      ],
                      v: [
                        [7.437, 1.911],
                        [31.287, 32.64],
                        [54.983, 1.911],
                        [31.287, -28.818],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 9',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 9,
                  ty: 'sh',
                  ix: 10,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-21.097, 0],
                        [0, -23.238],
                        [0, 0],
                        [0, 0],
                        [-14.829, 0],
                        [-3.21, 10.396],
                        [0, 0],
                        [15.441, 0],
                        [0, 25.684],
                      ],
                      o: [
                        [20.486, 0],
                        [0, 0],
                        [0, 0],
                        [0.153, 17.275],
                        [10.702, 0],
                        [0, 0],
                        [-4.739, 14.218],
                        [-21.709, 0],
                        [0, -25.072],
                      ],
                      v: [
                        [111.807, -38.144],
                        [144.064, -2.675],
                        [144.064, 4.663],
                        [88.569, 4.663],
                        [113.03, 32.487],
                        [134.586, 16.129],
                        [143.606, 19.798],
                        [112.265, 41.966],
                        [77.867, 2.37],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 10',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 10,
                  ty: 'sh',
                  ix: 11,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.306, -15.747],
                        [0, 0],
                        [0, 0],
                        [13.606, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, -15.747],
                        [-14.218, 0],
                      ],
                      v: [
                        [88.569, -4.051],
                        [133.21, -4.051],
                        [133.21, -5.427],
                        [111.654, -29.582],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 11',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 11,
                  ty: 'sh',
                  ix: 12,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [12.689, 0],
                        [2.293, 0.459],
                        [0, 0],
                        [-1.682, 0],
                        [-3.669, 10.396],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.764, -2.905],
                        [0, 0],
                        [-1.376, 4.128],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [-6.88, 18.804],
                        [-2.293, 0],
                        [0, 0],
                        [1.835, 0.306],
                        [9.937, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.529, 3.975],
                        [0, 0],
                        [0.764, -2.905],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [189.668, 45.023],
                        [161.691, 70.707],
                        [154.964, 70.095],
                        [154.964, 60.617],
                        [160.315, 61.076],
                        [178.661, 45.176],
                        [180.648, 39.519],
                        [150.072, -36.309],
                        [161.997, -36.309],
                        [182.483, 18.269],
                        [185.846, 28.665],
                        [186.152, 28.665],
                        [189.362, 18.116],
                        [208.014, -36.309],
                        [219.479, -36.309],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 12',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 12,
                  ty: 'sh',
                  ix: 13,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-21.097, 0],
                        [0, -23.238],
                        [0, 0],
                        [0, 0],
                        [-14.829, 0],
                        [-3.21, 10.396],
                        [0, 0],
                        [15.441, 0],
                        [0, 25.684],
                      ],
                      o: [
                        [20.486, 0],
                        [0, 0],
                        [0, 0],
                        [0.153, 17.275],
                        [10.702, 0],
                        [0, 0],
                        [-4.739, 14.218],
                        [-21.709, 0],
                        [0, -25.072],
                      ],
                      v: [
                        [258.571, -38.144],
                        [290.829, -2.675],
                        [290.829, 4.663],
                        [235.334, 4.663],
                        [259.794, 32.487],
                        [281.35, 16.129],
                        [290.37, 19.798],
                        [259.03, 41.966],
                        [224.632, 2.37],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 13',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 13,
                  ty: 'sh',
                  ix: 14,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.306, -15.747],
                        [0, 0],
                        [0, 0],
                        [13.606, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, -15.747],
                        [-14.218, 0],
                      ],
                      v: [
                        [235.334, -4.051],
                        [279.975, -4.051],
                        [279.975, -5.427],
                        [258.418, -29.582],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 14',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.078431375325, 0.074509806931, 0.086274512112, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'audioeye',
              np: 16,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 300,
          st: 0,
          bm: 0,
        },
      ],
      markers: [{ tm: 60, cm: 'end', dr: 0 }],
    })
    return () => setAnimationData(null)
  }, [])

  return (
    <div
      onMouseEnter={() => {
        setPlay(true)
      }}
      onMouseLeave={() => {
        setPlay(false)
      }}
    >
      <Lottie
        role="img"
        // aria-label={lottieAriaLabel || ''}
        // loop={loop || true}
        loop={play}
        animationData={animationData}
        play={play}
        speed={1}
        style={{ width: '142px', height: 'auto', margin: '0 auto' }}
      />
    </div>
  )
}

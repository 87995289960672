exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-blog-search-jsx": () => import("./../../../src/pages/blog/search.jsx" /* webpackChunkName: "component---src-pages-blog-search-jsx" */),
  "component---src-pages-digital-accessibility-index-executive-summary-jsx": () => import("./../../../src/pages/digital-accessibility-index/executive-summary.jsx" /* webpackChunkName: "component---src-pages-digital-accessibility-index-executive-summary-jsx" */),
  "component---src-pages-digital-accessibility-index-industry-reports-finance-jsx": () => import("./../../../src/pages/digital-accessibility-index/industry-reports/finance.jsx" /* webpackChunkName: "component---src-pages-digital-accessibility-index-industry-reports-finance-jsx" */),
  "component---src-pages-digital-accessibility-index-industry-reports-government-jsx": () => import("./../../../src/pages/digital-accessibility-index/industry-reports/government.jsx" /* webpackChunkName: "component---src-pages-digital-accessibility-index-industry-reports-government-jsx" */),
  "component---src-pages-digital-accessibility-index-industry-reports-index-jsx": () => import("./../../../src/pages/digital-accessibility-index/industry-reports/index.jsx" /* webpackChunkName: "component---src-pages-digital-accessibility-index-industry-reports-index-jsx" */),
  "component---src-pages-digital-accessibility-index-industry-reports-insurance-jsx": () => import("./../../../src/pages/digital-accessibility-index/industry-reports/insurance.jsx" /* webpackChunkName: "component---src-pages-digital-accessibility-index-industry-reports-insurance-jsx" */),
  "component---src-pages-digital-accessibility-index-industry-reports-media-jsx": () => import("./../../../src/pages/digital-accessibility-index/industry-reports/media.jsx" /* webpackChunkName: "component---src-pages-digital-accessibility-index-industry-reports-media-jsx" */),
  "component---src-pages-digital-accessibility-index-industry-reports-retail-jsx": () => import("./../../../src/pages/digital-accessibility-index/industry-reports/retail.jsx" /* webpackChunkName: "component---src-pages-digital-accessibility-index-industry-reports-retail-jsx" */),
  "component---src-pages-digital-accessibility-index-industry-reports-travel-jsx": () => import("./../../../src/pages/digital-accessibility-index/industry-reports/travel.jsx" /* webpackChunkName: "component---src-pages-digital-accessibility-index-industry-reports-travel-jsx" */),
  "component---src-pages-digital-accessibility-index-key-insights-form-accessibility-tips-jsx": () => import("./../../../src/pages/digital-accessibility-index/key-insights/form-accessibility-tips.jsx" /* webpackChunkName: "component---src-pages-digital-accessibility-index-key-insights-form-accessibility-tips-jsx" */),
  "component---src-pages-digital-accessibility-index-key-insights-image-accessibility-tips-jsx": () => import("./../../../src/pages/digital-accessibility-index/key-insights/image-accessibility-tips.jsx" /* webpackChunkName: "component---src-pages-digital-accessibility-index-key-insights-image-accessibility-tips-jsx" */),
  "component---src-pages-digital-accessibility-index-key-insights-index-jsx": () => import("./../../../src/pages/digital-accessibility-index/key-insights/index.jsx" /* webpackChunkName: "component---src-pages-digital-accessibility-index-key-insights-index-jsx" */),
  "component---src-pages-digital-accessibility-index-key-insights-link-accessibility-tips-jsx": () => import("./../../../src/pages/digital-accessibility-index/key-insights/link-accessibility-tips.jsx" /* webpackChunkName: "component---src-pages-digital-accessibility-index-key-insights-link-accessibility-tips-jsx" */),
  "component---src-pages-digital-accessibility-index-methodology-jsx": () => import("./../../../src/pages/digital-accessibility-index/methodology.jsx" /* webpackChunkName: "component---src-pages-digital-accessibility-index-methodology-jsx" */),
  "component---src-pages-digital-accessibility-index-next-steps-jsx": () => import("./../../../src/pages/digital-accessibility-index/next-steps.jsx" /* webpackChunkName: "component---src-pages-digital-accessibility-index-next-steps-jsx" */),
  "component---src-pages-lp-2023-digital-accessibility-index-jsx": () => import("./../../../src/pages/lp/2023-digital-accessibility-index.jsx" /* webpackChunkName: "component---src-pages-lp-2023-digital-accessibility-index-jsx" */),
  "component---src-pages-partner-scanner-index-jsx": () => import("./../../../src/pages/partner-scanner/index.jsx" /* webpackChunkName: "component---src-pages-partner-scanner-index-jsx" */),
  "component---src-pages-preview-jsx": () => import("./../../../src/pages/preview.jsx" /* webpackChunkName: "component---src-pages-preview-jsx" */),
  "component---src-pages-prismic-author-url-jsx": () => import("./../../../src/pages/{PrismicAuthor.url}.jsx" /* webpackChunkName: "component---src-pages-prismic-author-url-jsx" */),
  "component---src-pages-prismic-page-url-jsx": () => import("./../../../src/pages/{PrismicPage.url}.jsx" /* webpackChunkName: "component---src-pages-prismic-page-url-jsx" */),
  "component---src-pages-prismic-post-url-jsx": () => import("./../../../src/pages/{PrismicPost.url}.jsx" /* webpackChunkName: "component---src-pages-prismic-post-url-jsx" */),
  "component---src-pages-sales-scanner-index-jsx": () => import("./../../../src/pages/sales-scanner/index.jsx" /* webpackChunkName: "component---src-pages-sales-scanner-index-jsx" */),
  "component---src-pages-scan-boia-jsx": () => import("./../../../src/pages/scan/boia.jsx" /* webpackChunkName: "component---src-pages-scan-boia-jsx" */),
  "component---src-pages-scan-results-jsx": () => import("./../../../src/pages/scan/results.jsx" /* webpackChunkName: "component---src-pages-scan-results-jsx" */),
  "component---src-templates-posts-category-jsx": () => import("./../../../src/templates/posts-category.jsx" /* webpackChunkName: "component---src-templates-posts-category-jsx" */),
  "component---src-templates-posts-jsx": () => import("./../../../src/templates/posts.jsx" /* webpackChunkName: "component---src-templates-posts-jsx" */),
  "component---src-templates-posts-tag-jsx": () => import("./../../../src/templates/posts-tag.jsx" /* webpackChunkName: "component---src-templates-posts-tag-jsx" */)
}


import React from 'react'
import { graphql } from 'gatsby'
import ProductProcess from './ProductProcess'
import GreenhouseJobBoard from './GreenhouseJobBoard'
import PressReleasesGrid from './press/PressReleasesGrid'
import NewsItemsGrid from './press/NewsItemsGrid'
import ContrastChecker from '../ContrastChecker'
import InvestorRelations from './InvestorRelations'
import AccessibilityStatement from './AccessibilityStatement'
import CompetitorComparison from './CompetitorComparison'
import AccessibeComparison from './AccessibeComparison'
import UserWayComparison from './UserWayComparison'
import WCAGChecklistA from './WCAGChecklistA'
import WCAGChecklistAA from './WCAGChecklistAA'
import WCAGChecklistAAA from './WCAGChecklistAAA'

export default function StaticContentBlock({ slice: { primary } }) {
  const PRODUCT_PROCESS = 'product-process'
  const GREENHOUSE_BOARD = 'greenhouse-board'
  const PRESS_RELEASES = 'press-releases'
  const IN_THE_NEWS = 'in-the-news'
  const CONTRAST_CHECKER = 'contrast-checker'
  const INVESTOR_RELATIONS = 'investor-relations'
  const ACCESSIBILITY_STATEMENT = 'accessibility-statement'
  const COMPETITOR_COMPARISON = 'competitor-comparison'
  const ACCESSIBE_COMPARISON = 'accessibe-comparison'
  const USERWAY_COMPARISON = 'userway-comparison'
  const WCAG_CHECKLIST_A = 'wcag-checklist-a'
  const WCAG_CHECKLIST_AA = 'wcag-checklist-aa'
  const WCAG_CHECKLIST_AAA = 'wcag-checklist-aaa'

  switch (primary.component_type) {
    case PRODUCT_PROCESS:
      return <ProductProcess />
    case GREENHOUSE_BOARD:
      return <GreenhouseJobBoard />
    case PRESS_RELEASES:
      return <PressReleasesGrid />
    case IN_THE_NEWS:
      return <NewsItemsGrid />
    case CONTRAST_CHECKER:
      return <ContrastChecker />
    case INVESTOR_RELATIONS:
      return <InvestorRelations />
    case ACCESSIBILITY_STATEMENT:
      return <AccessibilityStatement />
    case COMPETITOR_COMPARISON:
      return <CompetitorComparison />
    case ACCESSIBE_COMPARISON:
      return <AccessibeComparison />
    case USERWAY_COMPARISON:
      return <UserWayComparison />
    case WCAG_CHECKLIST_A:
      return <WCAGChecklistA />
    case WCAG_CHECKLIST_AA:
      return <WCAGChecklistAA />
    case WCAG_CHECKLIST_AAA:
      return <WCAGChecklistAAA />
    default:
      return null
  }
}

export const query = graphql`
  fragment StaticContentBlock on PrismicPageDataBodyStaticComponent {
    ...SliceBase
    primary {
      component_type
    }
  }

  fragment PostStaticContentBlock on PrismicPostDataBodyStaticComponent {
    ...SliceBase
    primary {
      component_type
    }
  }
`

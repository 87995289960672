import React from 'react'
import Text from '../../common/Text'
import Space from '../../common/Space'
import Button from '../../common/Button'
import { StageWrapper, FormInner } from './utils'
import { BackArrow, ButtonWrapper } from '../common'

import LoadingGraphic from '../../../../static/get-started-free/icon-rotate.svg'

import generateCheckSiteTranslations from '../../../data/generateCheckSiteTranslations'
import { useUserLang } from '../../../context/UserLangContext'

const CheckSite = ({ stage, setStageOne, formData }) => {
  const { userLang } = useUserLang()
  const languageData = generateCheckSiteTranslations({ userLang })

  return (
    <>
      <StageWrapper open={stage === 2} aria-hidden={stage === 2 ? 'false' : 'true'} checkSite>
        {stage === 2 && (
          <>
            <BackArrow clickHandler={setStageOne} />
            <FormInner>
              <Text heading3 aria-level="1">
                {languageData?.loading?.heading}
              </Text>
              <Text>{languageData?.loading?.message}</Text>
              <div>
                <img src={LoadingGraphic} alt="" />
              </div>
            </FormInner>
          </>
        )}
      </StageWrapper>
      <StageWrapper open={stage === 'exists'} aria-hidden={stage === 'exists' ? 'false' : 'true'} checkSite>
        {stage === 'exists' && (
          <>
            <BackArrow clickHandler={setStageOne} />
            <FormInner>
              <Text heading3 center>
                {languageData?.accountExists?.heading}
              </Text>
              <Text smallBodyBold center>
                {languageData?.accountExists?.message}
              </Text>
              <ButtonWrapper>
                <Button
                  text={languageData?.accountExists?.buttonText}
                  to={`${process.env.GATSBY_AEYE_PORTAL_LOGIN_URL}?email=${encodeURIComponent(formData.email)}`}
                />
              </ButtonWrapper>
            </FormInner>
          </>
        )}
      </StageWrapper>
      <StageWrapper open={stage === 'error'} aria-hidden={stage === 'error' ? 'false' : 'true'} checkSite>
        {stage === 'error' && (
          <>
            <BackArrow clickHandler={setStageOne} />
            <FormInner style={{ display: 'inline' }}>
              <Text heading3>{languageData?.error?.heading}</Text>
              <Space height={16} />
              {languageData?.error?.message}
            </FormInner>
          </>
        )}
      </StageWrapper>
    </>
  )
}

export default CheckSite

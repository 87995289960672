/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import { AutoFocusInside } from 'react-focus-lock'

import { onboardingHsFormCollect } from '../hubspot/HubSpotFormAPI'
import Text from '../../common/Text'
import Button from '../../common/Button'
import Input from '../Input'
import PasswordInput from './PasswordInput'
import Space from '../../common/Space'
import PhoneNumberInput from '../PhoneNumberInput'
import { trackEvent, identify } from '../../../utils/eventTrack'
import { FormWrapper, StageWrapper, FormHeader, FormInner, FormHeaderWrapper } from './utils'
import { ButtonWrapper, InputContainer, NameInputWrapper } from '../common'
import { AgencyFormFields } from './AgencyFormFields'
import CheckSite from './CheckSite'
import checkSiteValidity from './checkSiteValidity'
import parseFullName from '../../../utils/parseFullName'
import validateEmail from '../../../utils/validateEmail'
import { ConsentFormFields } from './ConsentFormFields'
import { useShowConsentForm } from '../../../context/ConsentContext'
import { useUserLang } from '../../../context/UserLangContext'
import generateOnboardingFormTranslations from '../../../data/generateOnboardingFormTranslations'

export const ValidatedInputWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const HiddenForm = styled.div`
  display: none;
`

const OnboardingForm = ({ formName }) => {
  const [stage, setStage] = useState(1)
  const [passwordWarn, setPasswordWarn] = useState(false)
  const [passwordErr, setPasswordErr] = useState(false)
  const [emailError, setEmailError] = useState()
  const [provisionError, setProvisionError] = useState(true)
  const { showConsentForm } = useShowConsentForm()
  const { userLang } = useUserLang()
  const languageData = generateOnboardingFormTranslations({ userLang })
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    intlPhoneNum: '',
    freelancerAgency: '',
    legalConsentSubscription: false,
    legalConsentProcessing: false,
    company: '',
    url: '',
    password: '',
    planId: 'simple-monthly',
    form_name: formName,
  })

  const [sessionFormData, setSessionFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    freelancerAgency: '',
    company: '',
    url: '',
  })

  const formIds = {
    production: {
      consentRequired: {
        en: '3939be5c-dbd7-41d7-8b1a-76bdbed22a16',
        de: 'c6deb49b-8550-4d5e-baf6-adf722104284',
        fr: 'e68b7b48-d10a-4012-91f6-e726d39a2d6e',
        es: '239cfc14-026e-4cfa-9531-b8c654a1285f',
        it: 'd3ac6ff3-b05f-47ca-ba87-93644eba4a39',
      },
      noConsentRequired: {
        en: 'bba22006-a3f4-43e3-9194-93cf0317ca3c',
        de: '9996b19f-155e-44c0-a7e2-b4cac817b963',
        fr: '7b8a92a8-b2fb-4506-97bb-72524903f089',
        es: '6a100a0f-cb0d-4087-970d-03d8412051ba',
        it: '975886a4-38c1-44f9-99fd-0b10dd5fa4d3',
      },
    },
    staging: {
      consentRequired: {
        en: 'b8fc5ada-1fe3-4165-b50e-6c3c93c86f66',
        de: '57d08554-a7f6-4637-846a-d81c23890fff',
        fr: 'e0c3ca25-12ea-4b20-b82e-9109c59a1075',
        es: '9295a3a7-9d96-4634-8a3e-2df2652119c1',
        it: 'cbbe17a2-993a-4613-a042-067aa0e08ce9',
      },
      noConsentRequired: {
        en: 'c170e358-1960-46b2-8516-5e79dd2fba46',
        de: '25d0dfa3-8efa-416f-bf56-c4681f45ae2d',
        fr: '1862912f-5594-424b-b48a-3aa9c32bd015',
        es: '0df79691-c9d3-4d77-8042-8c09d9177edc',
        it: '4c69b363-9c5a-4a45-a114-4caf2eaee1ad',
      },
    },
  }

  const environment = process.env.GATSBY_ACTIVE_ENV === 'production' ? 'production' : 'staging'
  const consent = showConsentForm ? 'consentRequired' : 'noConsentRequired'
  const lang = userLang

  const formID = formIds[environment][consent][lang]

  const windowGlobal = typeof window !== `undefined` && window

  const updateFormData = prop => setFormData({ ...formData, ...prop })
  const updateSessionFormData = prop => setSessionFormData({ ...sessionFormData, ...prop })

  useEffect(() => {
    setStageOne()

    if (windowGlobal) {
      const scan_url = windowGlobal.sessionStorage.getItem('scan_url')
      const hsFormData = typeof window !== 'undefined' && window.sessionStorage.getItem('hs-form-data')
      const parsedData = JSON.parse(hsFormData)
      const { firstname, lastname, email, phone, company, agency_or_freelancer__c } = mapValues(
        keyBy(parsedData, 'name'),
        'value'
      )
      const sessionFirstName = firstname
      const sessionLastName = lastname
      const sessionEmail = email
      const sessionPhone = phone
      const sessionCompany = company
      const sessionAgency = agency_or_freelancer__c
      const noName = !!(!sessionFirstName && !sessionLastName)

      if (
        scan_url ||
        sessionFirstName ||
        sessionLastName ||
        sessionEmail ||
        sessionPhone ||
        sessionCompany ||
        sessionAgency
      ) {
        updateFormData({
          url: scan_url,
          fullName: noName ? '' : `${`${sessionFirstName} `}${sessionLastName}`,
          email: sessionEmail,
          phone: sessionPhone,
          intlPhoneNum: sessionPhone,
          company: sessionCompany,
          freelancerAgency: sessionAgency,
        })
        updateSessionFormData({
          url: scan_url,
          fullName: noName ? '' : `${`${sessionFirstName} `}${sessionLastName}`,
          email: sessionEmail,
          phone: sessionPhone,
          company: sessionCompany,
          freelancerAgency: sessionAgency,
        })
      }
    }
  }, [])

  const setStageOne = () => {
    trackEvent(`${formName} Viewed`)
    setStage(1)
  }

  const validateSite = async () => {
    const siteValidityResponse = await checkSiteValidity(formData.url)
    const siteValidityBody = await siteValidityResponse.json()

    if (siteValidityResponse.status === 409) {
      trackEvent(`API Exception`, {
        Label: 'URL already registered with AudioEye',
        'Full Exception': siteValidityBody,
      })
      setStage('exists')
      return false
    }

    if (siteValidityResponse.status === 400 && siteValidityBody.message === 'url must be an URL address') {
      trackEvent(`API Exception`, {
        Label: 'URL must be a url address',
        'Full Exception': siteValidityBody,
      })
      setStage('error')
      return false
    }
    return true
  }

  const submit = async evt => {
    evt.preventDefault()

    const timeout = ms => new Promise(resolve => setTimeout(resolve, ms))
    await timeout(0)

    if (emailError) {
      const emailErrAlert = windowGlobal && document.querySelector('#email-error-alert')
      if (emailErrAlert) emailErrAlert.focus()
      const emailInput = windowGlobal && document.querySelector('input[type="email"]')
      if (emailInput) emailInput.focus()
      return
    }

    if (passwordWarn) {
      setPasswordErr(true)
      const errAlert = windowGlobal && document.querySelector('#error-alert')
      if (errAlert) errAlert.focus()
      const passwordInput = windowGlobal && document.querySelector('#password-input')
      if (passwordInput) passwordInput.focus()
      return
    }

    setStage(2)
    const isValidSite = await validateSite()
    if (isValidSite) {
      setProvisionError(false)
    }
  }

  useEffect(() => {
    const fetchMagicLink = async () => {
      const magicLinkResponse = await fetch(`/api/fetch-magicLink`, {
        headers: {
          'Content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          formData,
        }),
      })

      const magicLinkBody = await magicLinkResponse.json()

      // Existing email/user
      if (magicLinkResponse.status === 409) {
        setStage('exists')
        setProvisionError(true)
        trackEvent(`API Exception`, {
          Label: 'Magic link existing user and incorrect password',
          'Full Exception': magicLinkBody,
        })
        return
      }

      // possible API 404
      if (magicLinkResponse.status === 404) {
        setStage('error')
        setProvisionError(true)
        trackEvent(`API Exception`, {
          Label: 'Magic link creation error. API 404',
          'Full Exception': magicLinkBody,
        })
        return
      }

      if (!magicLinkResponse.ok) {
        trackEvent(`API Exception`, {
          Label: 'Magic link creation error',
          'Full Exception': magicLinkBody,
        })
        setStage('error')
        setProvisionError(true)
        return
      }

      if (magicLinkResponse.status === 201) {
        const { subscriptionId, accountId, userId } = magicLinkBody
        const { email, fullName, url, planId } = formData
        const { firstName, lastName } = parseFullName(fullName)

        trackEvent(`${formName} Completed`, {
          category: `${formName.toLowerCase().replaceAll(' ', '_')}`,
          email,
          first_name: firstName,
          last_name: lastName,
          site_url: url || '',
          plan_name: planId,
          plan_price: '49',
          plan_period_unit: 'month',
          account_create_method: 'email',
          subscription_id: subscriptionId || '',
          subscription_status: 'in_trial',
          portal_account_id: accountId,
          portal_user_id: userId,
        })

        if (typeof window !== 'undefined') {
          identify({
            email,
          })

          if (window.sessionStorage.getItem('gate') === 'false') {
            trackEvent(`Trial After Free Scan`, {
              category: `${formName.toLowerCase().replaceAll(' ', '_')}`,
              email,
              first_name: firstName,
              last_name: lastName,
              site_url: url || '',
              plan_name: planId,
              plan_price: '49',
              plan_period_unit: 'month',
              account_create_method: 'email',
              subscription_id: subscriptionId || '',
              subscription_status: 'in_trial',
              portal_account_id: accountId,
              portal_user_id: userId,
            })
          }

          // Datadog setUser call
          if (window.datadogRum?.setUser) {
            window.datadogRum.setUser({
              email,
              first_name: firstName,
              last_name: lastName,
              site_url: url,
              subscription_id: subscriptionId || '',
              plan_name: planId,
            })
          }
        }
      }

      // No matter if the call to the signup is successful or not we want to send the data to HubSpot
      const pattern = /@e2e.staging-audioeye.com$/

      if (process.env.GATSBY_ACTIVE_ENV !== 'production') {
        if (!pattern.test(formData.email)) {
          await onboardingHsFormCollect({
            formData,
            portalAccountId: magicLinkBody?.accountId,
            portalUserId: magicLinkBody?.userId,
            showConsentForm,
            formID,
            userLang,
          })
        }
      } else {
        await onboardingHsFormCollect({
          formData,
          portalAccountId: magicLinkBody?.accountId,
          portalUserId: magicLinkBody?.userId,
          showConsentForm,
          formID,
          userLang,
        })
      }

      // Wait until after the form submission to redirect otherwise the api call will be cancelled
      if (magicLinkResponse.status === 201) {
        const { magicLink } = magicLinkBody
        window.location.href = magicLink
      }
    }
    if (!provisionError) {
      fetchMagicLink()
    }
  }, [provisionError])

  const createForm = () => {
    if (!window.hbspt) {
      setTimeout(createForm, 1)
    } else {
      window.hbspt.forms.create({
        portalId: `${process.env.GATSBY_HUBSPOT_PORTAL_ID}`,
        formId: formID,
        target: '#hidden-form',
      })

      trackEvent('Free Trial Form Viewed')

      return true
    }
  }

  const loadScript = () => {
    const script = document.createElement(`script`)
    script.defer = true
    script.onload = () => {
      createForm()
    }
    script.src = `//js.hsforms.net/forms/v2.js`
    document.head.appendChild(script)
  }

  /*
    This code was pulled from react-hubspot-form
    Doing this in order to create a page/form view but not actually using the
    generated html form from hubspot. uUing our own custom form because we need to fill
    some hidden fields/add custom fields/etc
  */
  useEffect(() => {
    if (windowGlobal) {
      if (!window.hbspt) {
        loadScript()
      } else {
        createForm()
      }
    }
  }, [])

  return (
    <FormWrapper id="form-wrapper">
      <StageWrapper id="stage-wrapper" open={stage === 1} aria-hidden={!stage === 1}>
        <FormInner id="form-inner" style={{ gap: '0px' }}>
          <FormHeaderWrapper>
            <FormHeader heading={languageData?.heading} required={languageData?.requiredField} />
          </FormHeaderWrapper>
          <HiddenForm id="hidden-form" />
          <form onSubmit={submit} id="get-started-onboard-modal-step-1">
            {!sessionFormData.fullName && (
              <>
                <NameInputWrapper id="name-input-wrapper">
                  <AutoFocusInside className="auto-focus-inside">
                    <Input
                      label={languageData?.fullNameField}
                      placeholder=""
                      autoComplete="given-name"
                      name="full_name"
                      onChange={e => updateFormData({ fullName: e.target.value })}
                      onBlur={e => {
                        trackEvent(`Form Value Changed`, {
                          'Form Name': `${formName}`,
                          'Form Element Name': e.target.name,
                          'Form Element Type': 'input',
                          'New Value': e.target.value,
                        })
                      }}
                      onFocus={e => {
                        trackEvent(`Form Value Focused`, {
                          'Form Name': `${formName}`,
                          'Form Element Name': e.target.name,
                          'Form Element Type': 'input',
                          'New Value': e.target.value,
                        })
                      }}
                      value={formData.fullName}
                      required
                      maxLength="256"
                      autoCapitalize="words"
                      id="name-input"
                    />
                  </AutoFocusInside>
                </NameInputWrapper>
                <Space height={4} />
              </>
            )}
            {!sessionFormData.email && (
              <>
                <InputContainer id="email-wrapper" style={{ position: 'relative' }}>
                  <Input
                    label={languageData?.workEmailField}
                    placeholder=""
                    name="email"
                    inputMode="email"
                    type="email"
                    required
                    value={formData.email}
                    onChange={e => {
                      if (
                        e.target.value.includes('@') &&
                        windowGlobal &&
                        window.location.pathname === '/get-started/'
                      ) {
                        validateEmail(e.target.value, setEmailError)
                      }
                      updateFormData({ email: e.target.value })
                    }}
                    onBlur={e => {
                      trackEvent(`Form Value Changed`, {
                        'Form Name': `${formName}`,
                        'Form Element Name': e.target.name,
                        'Form Element Type': 'input',
                        'New Value': e.target.value,
                      })
                    }}
                    onFocus={e => {
                      trackEvent(`Form Value Focused`, {
                        'Form Name': `${formName}`,
                        'Form Element Name': e.target.name,
                        'Form Element Type': 'input',
                        'New Value': e.target.value,
                      })
                    }}
                    id="email-input"
                  />
                  {emailError && (
                    <Text
                      smallBody
                      color="red500"
                      id="email-error-alert"
                      role="alert"
                      style={{ textAlign: 'right', position: 'absolute', right: '0', top: '8px' }}
                    >
                      Enter a valid business email
                    </Text>
                  )}
                </InputContainer>
                <Space height={4} />
              </>
            )}
            {!sessionFormData.phone && (
              <>
                <InputContainer id="phone-wrapper">
                  <ValidatedInputWrapper>
                    <PhoneNumberInput
                      updateFormData={updateFormData}
                      formData={formData}
                      id="phone-input"
                      labelText={languageData?.phoneNumberField}
                    />
                  </ValidatedInputWrapper>
                </InputContainer>
                <Space height={4} />
              </>
            )}
            {!sessionFormData.company && (
              <>
                <InputContainer id="company-wrapper">
                  <Input
                    label={languageData?.companyField}
                    name="company"
                    required
                    value={formData.company}
                    onChange={e => updateFormData({ company: e.target.value })}
                    id="company-input"
                    onBlur={e => {
                      trackEvent(`Form Value Changed`, {
                        'Form Name': `${formName}`,
                        'Form Element Name': e.target.name,
                        'Form Element Type': 'input',
                        'New Value': e.target.value,
                      })
                    }}
                    onFocus={e => {
                      trackEvent(`Form Value Focused`, {
                        'Form Name': `${formName}`,
                        'Form Element Name': e.target.name,
                        'Form Element Type': 'input',
                        'New Value': e.target.value,
                      })
                    }}
                    maxLength="512"
                  />
                </InputContainer>
                <Space height={4} />
              </>
            )}
            {windowGlobal && !windowGlobal.sessionStorage.getItem('scan_url') && (
              <>
                <InputContainer id="url-wrapper">
                  <Input
                    label={languageData?.websiteUrlField}
                    name="url"
                    id="url-input"
                    onChange={e => updateFormData({ url: e.target.value })}
                    value={formData.url}
                    required
                    style={{ flex: '1' }}
                    pattern="(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,30}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"
                    onInput={e => {
                      e.target.setCustomValidity('')
                      if (!e.target.validity.valid) {
                        e.target.setCustomValidity('Please enter a valid URL.')
                      }
                    }}
                    onBlur={e => {
                      trackEvent(`Form Value Changed`, {
                        'Form Name': `${formName}`,
                        'Form Element Name': e.target.name,
                        'Form Element Type': 'input',
                        'New Value': e.target.value,
                      })
                    }}
                    onFocus={e => {
                      trackEvent(`Form Value Focused`, {
                        'Form Name': `${formName}`,
                        'Form Element Name': e.target.name,
                        'Form Element Type': 'input',
                        'New Value': e.target.value,
                      })
                    }}
                  />
                </InputContainer>
                <Space height={4} />
              </>
            )}
            <PasswordInput
              passwordErr={passwordErr}
              setPasswordErr={setPasswordErr}
              updateFormData={updateFormData}
              formData={formData}
              passwordWarn={passwordWarn}
              setPasswordWarn={setPasswordWarn}
              labelText={languageData?.passwordField}
              helperText={languageData?.passwordHelperText}
            />
            {windowGlobal &&
              !sessionFormData.freelancerAgency &&
              !windowGlobal.sessionStorage.getItem('agency_viewed') && (
                <AgencyFormFields formData={formData} updateFormData={updateFormData} />
              )}
            <Space height={16} />
            {showConsentForm && (
              <>
                <ConsentFormFields formData={formData} updateFormData={updateFormData} />
                <Space height={16} />
              </>
            )}
            <ButtonWrapper>
              <Button text={languageData?.submitButtonText} type="submit" id="modal-submit-button" wordWrap />
            </ButtonWrapper>
            <Space height={16} />
            <Text
              tinyBody
              center
              color="gray700"
              style={{
                display: 'inline-block',
                width: '100%',
              }}
            >
              {formData.freelancerAgency ? languageData?.legalTextPartners : languageData?.legalText}
            </Text>
            {userLang !== 'en' && (
              <>
                <Space height={16} />
                <Text
                  tinyBody
                  center
                  color="gray700"
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                >
                  {languageData?.emailCommunications}
                </Text>
              </>
            )}
          </form>
        </FormInner>
      </StageWrapper>
      <CheckSite stage={stage} setStageOne={setStageOne} formData={formData} />
    </FormWrapper>
  )
}

export default OnboardingForm

import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import HubspotForm from 'react-hubspot-form'
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import { useShowConsentForm } from '../../context/ConsentContext'

import { trackEvent, identify } from '../../utils/eventTrack'

import { trackForm } from '../form/hubspot/HubSpotFormAPI'
import HubspotStyles from '../form/hubspot/hs-form-styles'
import Text from './Text'
import Space from './Space'
import { theme } from '../../styles'

const Wrapper = styled.div`
  width: 100%;
  min-height: ${p => (p.formLoaded ? 'auto' : '75vh')};

  [type='submit'] {
    width: 100% !important;
  }
`

const FormWrapper = styled.div`
  grid-column: 2 / 3;
  grid-row: 1/ 2;
  display: flex;
  flex-direction: column;
  transition: height 200ms ease;

  & > div {
    width: 100%;
    margin: 0 auto;
  }

  @media (max-width: 800px) {
    & > div,
    h4 {
      max-width: 600px;
      margin: 0 auto;
      width: 100%;
    }
  }
`

const RequiredWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  font-size: ${theme.fonts.size.tinyBody};
  span {
    line-height: 1.2 !important;
    color: ${theme.color.red500};
  }
`

const ColorContrastCheckerForm = ({ setShowGate }) => {
  const [formLoaded, setFormLoaded] = useState(false)
  const [success, setSuccess] = useState(false)
  const { showConsentForm, showConsentFormLoading } = useShowConsentForm()
  const stagingHubSpotFormId = 'd4d02f0d-adb1-4ddb-a721-6ecef4191102'
  const productionHubSpotFormId = '4c183289-7219-49e5-abca-58ccc5438f7a'
  const stagingHubSpotFormIdConsentRequired = 'd33d859e-84fd-4b6a-a7bc-13c4470db490'
  const productionHubSpotFormIdConsentRequired = 'bddbadff-9062-4439-a7ee-b947796233a8'

  let finalFormId

  if (process.env.GATSBY_ACTIVE_ENV === 'production') {
    finalFormId = showConsentForm ? productionHubSpotFormIdConsentRequired : productionHubSpotFormId
  } else {
    finalFormId = showConsentForm ? stagingHubSpotFormIdConsentRequired : stagingHubSpotFormId
  }

  async function formSubmitHandler(e) {
    if (e.data.type === 'hsFormCallback' && e.data.eventName === 'onFormFailedValidation') {
      const errors =
        e.data.data.length > 0 &&
        e.data.data?.filter(error => {
          if (error.errorTypes.length) {
            return error
          }
          return null
        })
      if (errors) {
        errors.forEach(error => {
          const fieldLabel = document.querySelector(
            `.hs-form-lander div.hs-form-field.hs_${error?.name} > label > span`
          )?.textContent
          const fieldWrapper = document.querySelector(
            `.hs-form-lander div.hs-form-field.hs_${error?.name} label.hs-error-msg`
          )
          if (fieldWrapper && fieldLabel) {
            fieldWrapper.textContent = `Please complete the ${fieldLabel.toLowerCase()} field.`
          }
        })

        const firstErr = errors[0]?.name
        if (firstErr) {
          const toFocus = document.querySelector(`.hs-form-lander .hs-input[name=${firstErr}]`)
          if (toFocus) toFocus.focus()
        }
      }
    }
    if (e.data.type === 'hsFormCallback' && e.data.eventName === 'onFormSubmit') {
      if (
        e.data.id === stagingHubSpotFormId ||
        e.data.id === productionHubSpotFormId ||
        e.data.id === stagingHubSpotFormIdConsentRequired ||
        e.data.id === productionHubSpotFormIdConsentRequired
      ) {
        trackForm(e.data.data, finalFormId)
        setSuccess(true)
        localStorage.setItem('color_contrast_checker_form_submitted', true)
        const mappedData = mapValues(keyBy(e.data.data, 'name'), 'value')
        identify(mappedData)
        trackEvent('Color Contrast Checker Gated Form Submitted')
        setShowGate(false)
      }
    }
  }

  useEffect(() => {
    if (formLoaded) {
      const persistedUtmParams = JSON.parse(sessionStorage.getItem('utm_params'))
      if (persistedUtmParams) {
        Object.entries(persistedUtmParams).forEach(param => {
          const utmHiddenInput = document.querySelector(`input[name="${param[0]}"]`)
          if (utmHiddenInput) utmHiddenInput.value = param[1] || ''
        })
      }

      const persistedGclidParam = JSON.parse(sessionStorage.getItem('gclid_param'))
      const gclidHiddenInput = document.querySelector('input[name="gclid__c"]')
      if (gclidHiddenInput) gclidHiddenInput.value = persistedGclidParam || ''

      const mutinyExperienceName = localStorage.getItem('mutiny_experience_name')
      const mutinySegmentName = localStorage.getItem('mutiny_segment_name')
      const mutinyVariationName = localStorage.getItem('mutiny_variation_name')

      const mutinyExperienceNameInput = document.querySelector(`input[name="mutiny_experience_name"]`)
      if (mutinyExperienceNameInput && mutinyExperienceName) mutinyExperienceNameInput.value = mutinyExperienceName

      const mutinySegmentNameInput = document.querySelector(`input[name="mutiny_segment_name"]`)
      if (mutinySegmentNameInput && mutinySegmentName) mutinySegmentNameInput.value = mutinySegmentName

      const mutinyVariationNameInput = document.querySelector(`input[name="mutiny_variation_name"]`)
      if (mutinyVariationNameInput && mutinyVariationName) mutinyVariationNameInput.value = mutinyVariationName
    }
  }, [formLoaded])

  useEffect(() => {
    window.addEventListener('message', formSubmitHandler)
    return () => {
      window.removeEventListener('message', formSubmitHandler)
    }
  }, [])

  return (
    <Wrapper css={HubspotStyles} formLoaded={formLoaded}>
      <div>
        <FormWrapper>
          <Text heading3 aria-level="2" color="purple500">
            Color Contrast Checker
          </Text>
          <Space height={8} />
          <Text bodyMedium>Get full access to ensure you're color combinations are accessible.</Text>
          <Space height={16} />
          {(stagingHubSpotFormId || productionHubSpotFormId) && (
            <>
              {!success && (
                <RequiredWrapper>
                  <span>*&nbsp;</span>
                  <Text tinyBody color="gray700">
                    Required Field
                  </Text>
                </RequiredWrapper>
              )}
              {!showConsentFormLoading && (
                <HubspotForm
                  portalId={process.env.GATSBY_HUBSPOT_PORTAL_ID}
                  formId={finalFormId}
                  onReady={() => {
                    setFormLoaded(true)
                    trackEvent('Color Contrast Checker Gated Form Viewed')
                  }}
                  onFormReady={() => {
                    const formInputs = document.querySelectorAll(`#hsForm_${finalFormId} input`)
                    formInputs.forEach(input => {
                      input.addEventListener('change', e => {
                        trackEvent(`Form Value Changed`, {
                          'Form Name': 'Color Contrast Checker Gated Form',
                          'Form Hubspot ID': finalFormId,
                          'Form Element Name': e.target.name,
                          'Form Element Type': 'input',
                          'New Value': e.target.value,
                        })
                      })

                      input.addEventListener('focus', e => {
                        trackEvent(`Form Value Focused`, {
                          'Form Name': 'Color Contrast Checker Gated Form',
                          'Form Hubspot ID': finalFormId,
                          'Form Element Name': e.target.name,
                          'Form Element Type': 'input',
                          'Current Value': e.target.value,
                        })
                      })
                    })

                    const formSelects = document.querySelectorAll(`#hsForm_${finalFormId} select`)
                    formSelects.forEach(select => {
                      select.addEventListener('change', e => {
                        trackEvent(`Form Value Changed`, {
                          'Form Element Name': e.target.name,
                          'Form Element Type': 'select',
                          'New Value': e.target.value,
                        })
                      })

                      select.addEventListener('focus', e => {
                        trackEvent(`Form Value Focused`, {
                          'Form Element Name': e.target.name,
                          'Form Element Type': 'select',
                          'Current Value': e.target.value,
                        })
                      })
                    })
                  }}
                  submitButtonClass="custom-hs-button"
                  cssClass="custom-hs-css hs-form-lander"
                />
              )}
            </>
          )}
        </FormWrapper>
      </div>
    </Wrapper>
  )
}

export default ColorContrastCheckerForm
